<template>
  <div id="forget-page">
    <v-flex xs10 sm8 md6 offset-xs1 offset-sm1 offset-md1 :class="isMobile ? 'forget-password-m' : 'forget-password'">
      <h1 v-if="!isMobile"> {{ $t('forget-password.subtitle')}}</h1>
      <p :class="isMobile ? 'text mt-9' : 'text'">
        {{ $t('forget-password.subtitle2')}}
      </p>

      <v-flex xs12 sm12 md6>
        <v-form
          ref="form"
          class="mt-9"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="email"
            :outlined="isMobile"
            ref="email"
            label="Email"
            :rules="emailRules"
            required
          ></v-text-field>

          <v-btn :class="isMobile ? 'mt-1' : 'mt-9'" tile color="black" dark width="100%" height="44px" @click="submit()">
            <span class="btn-text"> {{ $t('forget-password.send')}} </span>
          </v-btn>

          <v-btn v-if="isMobile" class="mt-2" tile color="white" width="100%" height="44px" @click="back()">
            <span class="btn-text"> {{ $t('back')}} </span>
          </v-btn>
        </v-form>
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Login',
     computed: {
      ...mapState([
        'isMobile',
        'isLogin'
      ])
    },
    data () {
      return {
        email: '',
        valid: true,
        emailRules: [
          v => !!v || this.$t('forget-password.form.error-email'),
          v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.trim()) || this.$t('forget-password.form.error-valid-email')
        ]
      }
    },
    methods: {
      back () {
        this.$router.push({ name: 'Login'})
      },
      submit () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          axios
            .post('/api/v1/member/forget', {
              email: this.email.trim()
            })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              
              this.$router.push({ name: 'Login'})
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } 
      }
    },
    clear () {
    }, 
    mounted () {
      if (this.isLogin) {
        this.$router.push({ name: 'My-Account'})
      }
      this.setMetaInfo(null, 'Forget Password')

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', 'PULIHKAN KATA SANDI')
    }
  }
</script>

<style scoped>
  .forget-password {
    color: #000000;
    position: relative;
    margin: auto;
    margin-top: 8rem;
  }

  .forget-password-m {
    color: #000000;
    position: relative;
    margin: auto;
    margin-top: 5rem;
  }

 .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
    font-style: normal !important;
    text-align: justify;
    text-justify: inter-word;
  }

  .btn-text {
    text-transform: uppercase;
  }
</style>
